import { Button, Paper, Stack } from "@mui/material";
import { IconChevronLeft } from "@tabler/icons";
import React from "react";
import { Link } from "react-router-dom";
import API from "../../utils/API";

const Appbar = (props) => {
  const saveEvent = () => {
    console.log("Data Event In AppBar : ", props.data);
    API.put(`events/editEvent/${props.params}`, props.data, {
      Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    })
      .then((res) => {
        console.log("Data Event : ", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteEvent = () => {
    console.log("Data Event In AppBar : ", props.data);
    // API.delete(`events/deleteEvent/${props.eventId}`, {
    //   Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    // })
    //   .then((res) => {
    //     console.log("Data Event : ", res.data.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  return (
    <Paper
      elevation={0}
      sx={{
        background: "transparent",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Link to={props.routeBack}>
        <Button
          variant="text"
          sx={{
            fontSize: "20px",
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Poppins, sans-serif",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          color="secondary"
          startIcon={<IconChevronLeft size={"24px"} />}
        >
          {props.title}
        </Button>
      </Link>
      <Stack
        spacing={1}
        direction="row"
        sx={{
          height: "30px",
        }}
      >
        {props.edit ? (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#62C4EF",
              fontWeight: 600,
              fontSize: "14px",
              fontStyle: "normal",
              "&:hover": {
                backgroundColor: "#4e9cbf",
              },
            }}
            size="small"
            onClick={() => {
              props.setEdit(false);
              saveEvent();
            }}
          >
            Simpan
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FC0000",
              fontWeight: 600,
              fontSize: "14px",
              fontStyle: "normal",
              "&:hover": {
                backgroundColor: "#B91C1C",
              },
            }}
            size="small"
            onClick={() => deleteEvent()}
          >
            Hapus
          </Button>
        )}
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#FECA13",
            fontWeight: 600,
            fontSize: "14px",
            fontStyle: "normal",
            "&:hover": {
              backgroundColor: "#CA8A04",
            },
          }}
          size="small"
          onClick={() => props.setEdit(true)}
        >
          Edit
        </Button>
      </Stack>
    </Paper>
  );
};

export default Appbar;
