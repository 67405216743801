import React from "react";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Container, Row, Col } from "reactstrap";
import { connect } from "react-redux";

import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "../assets/plugins/nucleo/css/nucleo.css";
import "../assets/scss/argon-dashboard-react.scss";
import "../assets/css/custom/auth.css";
import Login from "../views/auth/Login";

const Auth = ({ USER }) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="bg-white">
          <Row className="justify-content-center m-0">
            <Col
              className="bg-gradient-primary-auth py-6 py-sm-9 py-xl-8"
              xl="6"
              lg="5"
              md="12"
              sm="12"
            ></Col>

            <Col className="py-6 py-md-9" xl="6" lg="7" md="12" sm="12">
              <Container className="pb-5">
                <Row className="justify-content-center">
                  <Login />
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
