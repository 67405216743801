import {
  IconDashboard,
  IconPresentation,
  IconUsers,
  IconUser,
  IconShoppingCartPlus,
  IconCertificate,
} from "@tabler/icons";
import {
  IconCircleCheck,
  IconMessageCircle,
  IconAlertCircle,
} from "@tabler/icons";
import { IconWallet, IconCreditCard } from "@tabler/icons";
import { IconCalendarEvent, IconFileCertificate } from "@tabler/icons";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [
    {
      id: "dashboard",
      type: "group",
      children: [
        {
          id: "default",
          title: "Dashboard",
          type: "item",
          url: "/dashboard",
          icon: IconDashboard,
          breadcrumbs: false,
        },
        {
          id: "menuEvent",
          title: "Menu Event",
          type: "item",
          url: "/menu-event",
          icon: IconPresentation,
          breadcrumbs: false,
        },
        {
          id: "dataEventOrganizer",
          title: "Data Event Organizer",
          type: "item",
          url: "/event-organizer",
          icon: IconUsers,
          breadcrumbs: false,
        },
        {
          id: "dataUser",
          title: "Data User",
          type: "item",
          url: "/user",
          icon: IconUser,
          breadcrumbs: false,
        },
        {
          id: "profit",
          title: "Keuntungan",
          type: "item",
          url: "/benefit",
          icon: IconShoppingCartPlus,
          breadcrumbs: false,
        },
        {
          id: "certificate",
          title: "Sertifikat",
          type: "item",
          url: "/certificate",
          icon: IconCertificate,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "pages",
      type: "group",
      children: [
        {
          id: "adviceUser",
          title: "Saran / Kritik User",
          type: "item",
          icon: IconMessageCircle,
          url: "/saran-kritik",
          target: false,
          breadcrumbs: false,
        },
        {
          id: "reportUser",
          title: "Report Dari User",
          type: "item",
          icon: IconAlertCircle,
          url: "/report",
          target: false,
          breadcrumbs: false,
        },
        {
          id: "redCheckSubmission",
          title: "Pengajuan Centang Merah",
          type: "item",
          icon: IconCircleCheck,
          url: "/red-check-submission",
          target: false,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "finance",
      type: "group",
      children: [
        {
          id: "deposit",
          title: "Deposited Money",
          type: "item",
          url: "/deposit",
          icon: IconWallet,
          breadcrumbs: false,
        },
        {
          id: "withdraw",
          title: "Withdraw Money",
          type: "item",
          url: "/withdraw",
          icon: IconCreditCard,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "custom",
      type: "group",
      children: [
        {
          id: "customEvent",
          title: "Custom Event",
          type: "item",
          url: "/custom-event",
          icon: IconCalendarEvent,
          breadcrumbs: false,
        },
        {
          id: "customCertificate",
          title: "Custom Sertifikat",
          type: "item",
          url: "/custom-certificate",
          icon: IconFileCertificate,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default menuItems;
