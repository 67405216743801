import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import { useEffect, useState } from "react";

import ThemeRoutes from "../src/routes";
import themes from "./themes";

// ==============================|| APP ||============================== //

/**
 * Gunakan authProvider untuk provide login dan revoke and login
 * @returns Render app yang akan di load
 */
const App = () => {
  const customization = useSelector((state) => state.customization);
  const [isLogin, setIsLogin] = useState(false);
  const [accessToken, setAccessToken] = useState();

  const getLogin = () => {
    setAccessToken(localStorage.getItem("hievent-at"));
    return accessToken == null ? setIsLogin(false) : setIsLogin(true);
  };
  const props = {
    isLogin: isLogin,
    accessToken: accessToken,
  };

  useEffect(() => {
    getLogin();
  }, [isLogin, accessToken]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <ThemeRoutes {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
