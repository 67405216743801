import { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useLocalStorage("hievent-at", null);
  // const [userId, setUserId] = useLocalStorage("hievent-uid", null);
  const navigate = useNavigate();

  const login = (data) => {
    if (data.role === "admin") {
      setAccessToken(data.accessToken);
      navigate("/dashboard");
      window.location.reload();
    } else {
      navigate("/not-found");
    }
  };

  const logout = () => {
    localStorage.removeItem("hievent-at");
    setAccessToken("");
    // setUserId("");
    navigate("/auth/login");
    window.location.reload();
  };

  const auth = accessToken ? { accessToken } : null;

  return (
    <AuthContext.Provider value={{ auth, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const UseAuth = () => {
  return useContext(AuthContext);
};
