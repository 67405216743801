import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Grid, Box, Button } from "@mui/material";

// project imports
import {
  ExportButton,
  TotalEvent,
  SortBy,
} from "../../components/ui-component/cards/CardComponent";

const columns = [
  { id: "namaEo", label: "NAMA EO", minWidth: 50, align: "left" },
  {
    id: "jumlahPengikut",
    label: "JUMLAH PENGIKUT",
    minWidth: 100,
    align: "center",
  },
  {
    id: "saldoUtama",
    label: "SALDO UTAMA",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "saldoDirequest",
    label: "SALDO DIREREQUEST",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "tinjau",
    label: "TINJAU",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "aksi",
    label: "AKSI EVENT",
    minWidth: 170,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(
  namaEo,
  jumlahPengikut,
  saldoUtama,
  saldoDirequest,
  tinjau
) {
  return { namaEo, jumlahPengikut, saldoUtama, saldoDirequest, tinjau };
}

const rows = [];

for (let index = 1; index <= 10; index++) {
  const rowData = createData(
    "Tokopedia",
    "#2356",
    "Rp. 12.000.000",
    "Rp. 2.000.000"
  );
  rows.push(rowData);
}

const Withdraw = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortingOrder, setSortingOrder] = React.useState("");
  const [buttonValue, setButtonValue] = React.useState("");
  const [hideApproveButtonSetujui, setHideApproveButtonSetujui] =
    React.useState(false);
  const [hideApproveButtonDitolak, setHideApproveButtonDitolak] =
    React.useState(false);
  const [hideApproveButton, setHideApproveButton] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleButtonClick = (value) => {
    setButtonValue(value);

    if (value === "Proses") {
      setHideApproveButtonSetujui(!hideApproveButtonSetujui);
      setHideApproveButton(!hideApproveButton);
    } else {
      setHideApproveButtonDitolak(!hideApproveButtonDitolak);
      setHideApproveButton(!hideApproveButton);
    }
  };

  const handleChangeSorting = (newSortingOrder) => {
    setSortingOrder(newSortingOrder);
  };

  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "start",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <TotalEvent showText={"Event Bersertifikat : 5,222"} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
              height: "100%",
            }}
          >
            <Box
              style={{
                display: "flex",
                color: "#737373",
                fontWeight: 500,
                fontStyle: "normal",
              }}
              sx={{
                fontSize: { lg: 20, xs: 16 },
              }}
            >
              Total Request Pencairan Rp. 25.222.000
            </Box>
            <Box
              style={{
                display: "flex",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "5px",
                backgroundColor: "#9870C5",
                color: "white",
                fontStyle: "normal",
                fontWeight: 500,
              }}
              sx={{
                fontSize: { lg: 20, xs: 16 },
              }}
            >
              Sudah Di Proses Rp. 20.000.000
            </Box>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            flexDirection: "row",
            gap: "20px",
            width: "100%",
            paddingLeft: "30%",
          }}
        >
          <SortBy shadow="0" onChangeSorting={handleChangeSorting} />
          <ExportButton />
        </div>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 0,
          boxShadow: "1",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "tinjau" ? (
                              <a
                                href="#"
                                style={{ textDecoration: "underline" }}
                              >
                                Lihat Feedback
                              </a>
                            ) : column.id === "aksi" ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  gap: "10px",
                                }}
                              >
                                <Button
                                  style={{
                                    backgroundColor:
                                      buttonValue === "Tolak"
                                        ? "Red"
                                        : "#9870C5",
                                    height: "24px",
                                    borderRadius: "5px",
                                    color: "white",
                                    display: hideApproveButton
                                      ? "none"
                                      : "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleButtonClick("Tolak")}
                                >
                                  {buttonValue === "Tolak"
                                    ? "DiTolak"
                                    : "Tolak"}
                                </Button>
                                <Button
                                  style={{
                                    backgroundColor:
                                      buttonValue === "Proses"
                                        ? "green"
                                        : "#9870C5",
                                    height: "24px",
                                    borderRadius: "5px",
                                    color: "white",
                                    display: hideApproveButton
                                      ? "none"
                                      : "flex",
                                    alignItems: "center",
                                  }}
                                  onClick={() => handleButtonClick("Proses")}
                                >
                                  {buttonValue === "Proses"
                                    ? "Proses"
                                    : "Proses"}
                                </Button>
                                {hideApproveButtonSetujui ? (
                                  <p
                                    style={{
                                      color: "#5BE462",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Sudah Diproses
                                  </p>
                                ) : hideApproveButtonDitolak ? (
                                  <p
                                    style={{
                                      color: "#FC0000",
                                      fontSize: "14px",
                                      fontWeight: 400,
                                    }}
                                  >
                                    DiTolak
                                  </p>
                                ) : null}
                              </div>
                            ) : column.format && typeof value === "number" ? (
                              column.format(value)
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
};

export default Withdraw;
