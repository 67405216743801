// import { toast } from "react-toastify";

// export const showToast = (message, status, position, theme) => {
//   toast[status ? status : "info"](message, {
//     position: position ? position : "top-center",
//     autoClose: 5000,
//     hideProgressBar: false,
//     closeOnClick: true,
//     pauseOnHover: true,
//     draggable: true,
//     progress: undefined,
//     theme: theme ? theme : "light",
//   });
// };

import { toast } from "react-toastify";

export const showToast = (message, status, position, theme) => {
  // Define an array of valid status values
  const validStatuses = ["success", "info", "warning", "error"];

  // Use the status variable to determine the function name
  const toastFunction = validStatuses.includes(status) ? status : "info";

  toast[toastFunction](message, {
    position: position ? position : "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: theme ? theme : "light",
  });
};
