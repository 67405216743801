import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DialogSelect = (props) => {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  return (
    <div>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        sx={{ height: 30 }}
      >
        Tambah
      </Button>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"xs"}
      >
        <DialogTitle
          sx={{
            fontSize: "14px",
            color: "#737373",
            fontWeight: 400,
            fontStyle: "normal",
          }}
        >
          {props.label}
        </DialogTitle>
        <DialogContent>
          <Box
            component="form"
            sx={{ display: "flex", flexWrap: "wrap" }}
            fullWidth
          >
            <FormControl sx={{ m: 1, minWidth: 120 }} fullWidth>
              <InputLabel id="demo-dialog-select-label">
                {props.label === "Benefit Untuk Peserta"
                  ? "Benefit"
                  : "Prasyarat"}
              </InputLabel>
              <Select
                labelId="demo-dialog-select-label"
                id="demo-dialog-select"
                value={value}
                onChange={(e) => setValue(e.target.value)}
                input={
                  <OutlinedInput
                    label={
                      props.label === "Benefit Untuk Peserta"
                        ? "Benefit"
                        : "Prasyarat"
                    }
                  />
                }
              >
                <MenuItem value="" disabled>
                  <em>None</em>
                </MenuItem>
                {Array.isArray(props.data) &&
                  props.data.map((item) => (
                    <MenuItem value={item._id} key={item._id}>
                      {item.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              const updateEvent = { ...props.eventDetail };
              if (props.label === "Benefit Untuk Peserta") {
                const arrayValue = props.data.filter(
                  (item) => item._id === value
                );
                updateEvent.benefitIds = [
                  ...updateEvent.benefitIds,
                  ...arrayValue,
                ];
                props.setEventDetail(updateEvent);
              }
              if (props.label === "Prasyarat") {
                const arrayValue = props.data.filter(
                  (item) => item._id === value
                );
                updateEvent.preconditionIds = [
                  ...updateEvent.preconditionIds,
                  ...arrayValue,
                ];
                props.setEventDetail(updateEvent);
              }
              setOpen(false);
            }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DialogSelect;
