import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { Grid } from "@mui/material";
import {
  TotalEvent,
  ExportButton,
  SortBy,
} from "../../components/ui-component/cards/CardComponent";

// import { useDataContext } from "../../../hooks/useDataContext";
// import { number } from "yup";

const columns = [
  { id: "namaEo", label: "NAMA EO", minWidth: 50 },
  {
    id: "jenisEvent",
    label: "JENIS EVENT",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
  {
    id: "jumlahPeserta",
    label: "JUMLAH PESERTA",
    minWidth: 50,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "saldo",
    label: "SALDO PER EVENT",
    minWidth: 50,
    align: "center",
    format: (value) => value.toFixed(2),
  },
  {
    id: "batasPendaftaran",
    label: "BATAS PENDAFTARAN",
    minWidth: 50,
    align: "center",
    format: (value) => value.toLocaleString("en-US"),
  },
];

function createData(
  namaEo,
  jenisEvent,
  jumlahPeserta,
  saldo,
  batasPendaftaran
) {
  return { namaEo, jenisEvent, jumlahPeserta, saldo, batasPendaftaran };
}

const rows = [];

for (let index = 1; index <= 10; index++) {
  const rowData = createData(
    "Tokopedia",
    "Webinar",
    2000,
    "Rp. 12.000.000",
    "22 November 2022"
  );
  rows.push(rowData);
}

const Deposit = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [sortingOrder, setSortingOrder] = React.useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeSorting = (newSortingOrder) => {
    setSortingOrder(newSortingOrder);
  };
  return (
    <Grid
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          gap: "20px",
          marginBottom: "10px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "20px",
            height: "100%",
          }}
        >
          <TotalEvent userCount={25222000} showText={"Uang Masuk : "} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            flexDirection: "row",
            gap: "20px",
            width: "50%",
          }}
        >
          <SortBy onChangeSorting={handleChangeSorting} />
          <ExportButton />
        </div>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 0,
          boxShadow: "1",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.code}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiInputBase-colorPrimary": {
              display: "none",
            },
            "& .MuiTablePagination-displayedRows": {
              display: "flex",
              alignItems: "center",
              mt: 1,
            },
          }}
        />
      </Paper>
    </Grid>
  );
};

export default Deposit;
