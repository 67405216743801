import { useState } from "react";

import { Button, Modal, Spinner } from "reactstrap";
import API from "../../utils/API";
import { UseAuth } from "../../routes/useAuth";
import jwtDecode from "jwt-decode";

const ExistSession = ({ props }) => {
  const [loading, setLoading] = useState(false);

  const useAuth = UseAuth();

  const onRevoke = () => {
    const data = {
      sessionId: props.message.sessionId,
    };

    API.post("auths/revokeAndLogin", data)
      .then((res) => {
        props.setIsOpen(false);
        setLoading(false);
        props.setMessage(res.data);

        const decode = jwtDecode(res.data.data.accessToken);
        const data = {
          accessToken: res.data.data.accessToken,
          role: decode.roles[1].name,
        };

        // console.log("Data Response : ", data);
        useAuth.login(data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      className="modal-dialog-centered modal-info"
      contentClassName="bg-gradient-purple"
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
    >
      <div className="modal-header">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setIsOpen(!props.isOpen)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="py-3 text-center">
          <i className="ni ni-bell-55 ni-2x" />
          <p>Akun kamu yang login di perangkat lain akan otomatis keluar!</p>
          <span>Lanjutkan ?</span>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          disabled={loading}
          onClick={() => onRevoke()}
          className="btn-white"
          color="default"
          type="button"
        >
          {loading ? <Spinner size="sm" /> : null} Lanjutkan
        </Button>
        <Button
          className="text-white ml-auto"
          color="link"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setIsOpen(!props.isOpen)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ExistSession;
