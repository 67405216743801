// material-ui
import { useTheme } from "@mui/material/styles";
import logo from "../../assets/img/brand/logo_hievents.png";

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src={logo} alt="Berry" width="50" />;
};

export default Logo;
