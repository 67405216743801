import { combineReducers } from "redux";

// reducer import
import customizationReducer from "./customizationReducer";
import user from "./user";
import eo from "./eo";
import menuEventReducer from "./menuEvent";
import certificateReducer from "./certificate";

// ==============================|| COMBINE REDUCER ||============================== //

export default combineReducers({
  customization: customizationReducer,
  userReducer: user,
  eoReducer: eo,
  menuEventReducer: menuEventReducer,
  certificateReducer: certificateReducer,
});
