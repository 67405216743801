import React from "react";

const EventSelect = (props) => {
  return (
    <select
      style={{
        width: "200px",
        height: "30px",
        border: "none",
        backgroundColor: "transparent",
        fontWeight: 400,
        fontSize: "14px",
        color: "#737373",
        textAlign: "start",
        fontStyle: "normal",
        padding: 0,
      }}
      value={props.value}
      onChange={(e) => {
        const newValue = e.target.value;
        const updateEvent = { ...props.eventDetail };
        if (props.label === "Jenis Event") {
          updateEvent.eventType = newValue;
          props.setEventDetail(updateEvent);
        }
        if (props.label === "Konteks Event") {
          updateEvent.eventContext = newValue;
          props.setEventDetail(updateEvent);
        }
        if (props.label === "Biaya") {
          updateEvent.paidStatus = newValue;
          props.setEventDetail(updateEvent);
        }
      }}
    >
      <option value="None" disabled>
        None
      </option>
      {props.label === "Biaya" && (
        <>
          <option value="free">Gratis</option>
          <option value="paid">Berbayar</option>
        </>
      )}
      {props.label !== "Biaya" &&
        Array.isArray(props.data) &&
        props.data.map((item) => (
          <option
            key={item._id}
            value={item._id}
            sx={{
              fontWeight: 400,
              fontSize: "14px",
              color: "#737373",
              textAlign: "start",
              fontStyle: "normal",
            }}
          >
            {item.name}
          </option>
        ))}
    </select>
  );
};

export default EventSelect;
