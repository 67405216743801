import { useTheme } from "@mui/material/styles";
import { Box, Drawer, useMediaQuery, ButtonBase } from "@mui/material";
import { MobileView } from "react-device-detect";
import { drawerWidth } from "../../reducer/constant";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN } from "../../reducer/actions";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import MenuList from "./MenuList";
import Logo from "../../components/ui-component/Logo";

// ==============================|| SIDEBAR DRAWER ||============================== //

const Sidebar = ({ drawerOpen, drawerToggle, window }) => {
  const theme = useTheme();
  const matchUpMd = useMediaQuery(theme.breakpoints.up("md"));
  const defaultId = useSelector((state) => state.customization.defaultId);
  const dispatch = useDispatch();

  const drawer = (
    <>
      <Box sx={{ display: { xs: "block", md: "inline" } }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            mx: "auto",
            alignItems: "center",
            flexDirection: "row",
            gap: 2,
          }}
        >
          <ButtonBase
            disableRipple
            onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })}
            component={Link}
            to="/dashboard"
          >
            <Logo />
          </ButtonBase>
          <Box sx={{ color: "#9870C5", fontWeight: "bold", fontSize: 30 }}>
            HiEvent
          </Box>
        </Box>
      </Box>
      {/* <BrowserView> */}
      <PerfectScrollbar
        component="div"
        style={{
          height: "100%",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <MenuList />
        {/* <MenuCard /> */}
      </PerfectScrollbar>
      {/* </BrowserView> */}
      <MobileView>
        <Box sx={{ px: 2 }}>
          <MenuList />
          {/* <MenuCard /> */}
        </Box>
      </MobileView>
    </>
  );

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { md: 0 }, width: matchUpMd ? drawerWidth : "auto" }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant={matchUpMd ? "persistent" : "temporary"}
        anchor="left"
        open={drawerOpen}
        onClose={drawerToggle}
        sx={{
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            background: theme.palette.background.default,
            color: theme.palette.text.primary,
            borderRight: "none",
            [theme.breakpoints.up("md")]: {
              top: "0px",
            },
          },
        }}
        ModalProps={{ keepMounted: true }}
        color="inherit"
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

Sidebar.propTypes = {
  drawerOpen: PropTypes.bool,
  drawerToggle: PropTypes.func,
  window: PropTypes.object,
};

export default Sidebar;
