const initialState = {
  eoState: {},
  eoFilter: {},
};

const eoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EO":
      return {
        ...state,
        eoState: action.value,
      };

    case "FILTER_EO":
      const filteredEO = state.eoState.filter(
        (eo) =>
          eo.name.toLowerCase().includes(action.value.toLowerCase()) ||
          eo.namaEo.toLowerCase().includes(action.value.toLowerCase()) ||
          eo.emailEo.toLowerCase().includes(action.value.toLowerCase()) ||
          eo._id.toLowerCase().includes(action.value.toLowerCase()) ||
          eo.createdAt.toLowerCase().includes(action.value.toLowerCase())
      );
      return {
        ...state,
        eoFilter: filteredEO,
      };

    case "RESET_FILTER_EO":
      return {
        ...state,
        eoState: state.eoState,
      };

    case "RESET_STATE":
      return initialState;

    default:
      return state;
  }
};

export default eoReducer;
