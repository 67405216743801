import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs from "dayjs";

const InputDate = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        sx={{
          fontWeight: 400,
          fontSize: "14px",
          color: "#737373",
          textAlign: "start",
          fontStyle: "normal",
        }}
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        value={dayjs(props.value)}
        onChange={(value) => {
          const dateValue = dayjs(value)
            .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
            .toString();
          const updateEvent = { ...props.eventDetail };
          if (props.label === "Tanggal Mulai Event") {
            updateEvent.startDate = dateValue;
            props.setEventDetail(updateEvent);
          }
          if (props.label === "Tanggal Berakhir Event") {
            updateEvent.endDate = dateValue;
            props.setEventDetail(updateEvent);
          }
          if (props.label === "Deadline Pendaftaran") {
            updateEvent.deadlineRegistration = dateValue;
            props.setEventDetail(updateEvent);
          }
        }}
      />
    </LocalizationProvider>
  );
};

export default InputDate;
