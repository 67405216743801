import React from "react";
import Appbar from "../../components/items/Appbar";
import DetailCard from "../../components/items/DetailCard";
import { useParams } from "react-router-dom";
import { UseAuth } from "../../routes/useAuth";
import API from "../../utils/API";
import TableDetail from "../../components/items/event-organizer/TableDetail";

const Detail = () => {
  const [editEO, setEditEO] = React.useState(false);
  const [eoDetail, setEoDetail] = React.useState({});

  const params = useParams();
  const useAuth = UseAuth();

  const getEoDetail = () => {
    API.get(`/eventOrganizers/detail/${params.id}`, {
      headers: { Authorization: `Bearer ${useAuth.auth.accessToken}` },
    })
      .then((res) => {
        console.log("Data EO Detail : ", res.data.data);
        setEoDetail(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getEoDetail();
  }, []);

  return (
    <>
      <Appbar
        {...{
          title: "Detail Event Organizer",
          routeBack: "/event-organizer",
          edit: editEO,
          setEdit: setEditEO,
        }}
      />
      <DetailCard
        {...{
          label: "Event Organizer",
          data: eoDetail,
          setData: setEoDetail,
          edit: editEO,
          useAuth,
        }}
      />
      <TableDetail />
    </>
  );
};

export default Detail;
