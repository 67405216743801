import {
  Paper,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
} from "@mui/material";
import React from "react";
import HeaderTable from "../Partials/HeaderTable";

function createData(
  idUser,
  username,
  namaLengkap,
  email,
  cityAddress,
  waNumber,
  registrationLimit,
  attendTime
) {
  return {
    idUser,
    username,
    namaLengkap,
    email,
    cityAddress,
    waNumber,
    registrationLimit,
    attendTime,
  };
}

const rows = [];

for (let index = 1; index <= 10; index++) {
  const paddedIndex = String(index).padStart(3, "0");
  const rowData = createData(
    paddedIndex,
    "teddyal",
    "Teddy Alfansyah",
    "teddyalfa222@gmailcom",
    "Balikpapan",
    "081111223345",
    "06 oct 2023 23.30",
    "06 oct 2023 23.30"
  );
  rows.push(rowData);
}

const DetailTable = ({ headerTitle }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [columnTable, setColumnTable] = React.useState([]);

  React.useEffect(() => {
    getColumn();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getColumn = () => {
    const userColumn = HeaderTable.find(
      (item) => item.name === "detail_peserta_event"
    );

    if (userColumn) {
      const updatedColumnTable = userColumn.items.map((item) => {
        if (
          headerTitle === "Detail Absensi" &&
          item.label === "WAKTU PENDAFTARAN"
        ) {
          // Update label and id for this specific column
          return {
            ...item,
            label: "WAKTU ABSENSI",
            id: "attendTime", // Change id to "attendTime"
          };
        }
        return item; // Return the item unchanged for other columns
      });

      setColumnTable(updatedColumnTable);
    }
  };

  return (
    <Paper
      elevetion={0}
      style={{
        backgroundColor: "transparent",
        borderRadius: 0,
        display: "flex",
        flexDirection: "column",
        gap: 16,
        marginTop: 10,
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <p
          style={{
            fontSize: "20px",
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Poppins, sans-serif",
            color: "#9870C5",
          }}
        >
          {headerTitle}
        </p>
        <p
          style={{
            fontSize: "20px",
            fontWeight: 500,
            fontStyle: "normal",
            fontFamily: "Poppins, sans-serif",
            color: "#737373",
          }}
        >
          10 Peserta
        </p>
      </Stack>
      <TableContainer
        sx={{
          maxHeight: 600,
          backgroundColor: "#FFFFFF",
          boxShadow: "2",
          position: "relative",
        }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columnTable.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth, fontWeight: "bold" }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columnTable.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "eoYangReport" ? (
                            <>
                              <a
                                href="#"
                                style={{ textDecoration: "underline" }}
                              >
                                {value}
                              </a>
                            </>
                          ) : column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiTablePagination-selectLabel": {
              display: "none",
            },
            "& .MuiInputBase-colorPrimary": {
              display: "none",
            },
            "& .MuiTablePagination-displayedRows": {
              display: "flex",
              alignItems: "center",
              mt: 1,
            },
            position: "sticky",
            bottom: 0, // Keep the TablePagination component at the bottom
            backgroundColor: "white", // Optional: Add a white background to make it stand out
            zIndex: 1,
          }}
        />
      </TableContainer>
    </Paper>
  );
};

export default DetailTable;
