import { useState, useRef, useEffect } from "react";
import { UseAuth } from "../../routes/useAuth";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { showToast } from "../Partials/Toast";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography,
} from "@mui/material";
import { IconLogout, IconSettings, IconUser } from "@tabler/icons";
import API from "../../utils/API";
import PerfectScrollbar from "react-perfect-scrollbar";
import MainCard from "../../components/ui-component/cards/MainCard";
import Transitions from "../../components/ui-component/extended/Transitions";
import User1 from "../../assets/images/users/user-round.svg";

// ==============================|| PROFILE MENU ||============================== //

const Profil = () => {
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const customization = useSelector((state) => state.customization);
  const navigate = useNavigate();
  const useAuth = UseAuth();

  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const onLogout = () => {
    // console.log("Logout Token : ", useAuth.auth.accessToken);
    // const authToken =
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2M2ZkZWYzZTFiNWYzMDlkYmZiM2M2MDUiLCJyb2xlcyI6W3siX2lkIjoiNjM0NjJlNmFkZjdiYmRhNTIyNjYzMmYzIiwibmFtZSI6InVzZXIiLCJkZXNjcmlwdGlvbiI6Ii0ifSx7Il9pZCI6IjYzNDYyZTU1ZGY3YmJkYTUyMjY2MzJmMiIsIm5hbWUiOiJhZG1pbiIsImRlc2NyaXB0aW9uIjoiLSJ9XSwic2lkIjoiNjUwN2I3ZDYzZjc2Zjg2MTljNzliNDM4IiwiaXNFbWFpbFZlcmlmaWVkIjp0cnVlLCJpYXQiOjE2OTUwMDQ2MzAsImV4cCI6MTY5NTYwOTQzMCwiYXVkIjoiNjM0NjJlNmFkZjdiYmRhNTIyNjYzMmYzIiwiaXNzIjoiaGlldmVudHMuY28iLCJzdWIiOiI2M2ZkZWYzZTFiNWYzMDlkYmZiM2M2MDUifQ.6RaPYi-UzuJad5TIGFZnKwMNePCbpxKxMb0Lp74wqOw";
    API.get("auths/logout", {
      headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
    })
      .then((res) => {
        useAuth.logout();
      })
      .catch((err) => {
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event, index, route = "") => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== "") {
      navigate(route);
    }
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "48px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        icon={
          <Avatar
            src={User1}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
              cursor: "pointer",
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            color="inherit"
          />
        }
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            color={theme.palette.primary.main}
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
        sx={{
          // zIndex: (theme) => theme.zIndex.drawer + 100
          zIndex: 999999999,
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack sx={{ pb: 2 }}>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography
                          component="span"
                          variant="h4"
                          sx={{ fontWeight: 400 }}
                        >
                          Johne Doe
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2">Project Admin</Typography>
                    </Stack>
                    <Divider />
                  </Box>
                  <PerfectScrollbar
                    style={{
                      height: "100%",
                      maxHeight: "calc(100vh - 250px)",
                      overflowX: "hidden",
                    }}
                  >
                    <Box sx={{ px: 2 }}>
                      <List
                        component="nav"
                        sx={{
                          width: "100%",
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: "10px",
                          [theme.breakpoints.down("md")]: {
                            minWidth: "100%",
                          },
                          "& .MuiListItemButton-root": {
                            mt: 0.5,
                          },
                        }}
                      >
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 0}
                          onClick={(event) =>
                            handleListItemClick(
                              event,
                              0,
                              "/user/account-profile/profile1"
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3em" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">
                                Account Settings
                              </Typography>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 1}
                          onClick={(event) =>
                            handleListItemClick(
                              event,
                              1,
                              "/user/social-profile/posts"
                            )
                          }
                        >
                          <ListItemIcon>
                            <IconUser stroke={1.5} size="1.3em" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Grid
                                container
                                spacing={1}
                                justifyContent="space-between"
                              >
                                <Grid item>
                                  <Typography variant="body2">
                                    Social Profile
                                  </Typography>
                                </Grid>
                                <Grid item>
                                  <Chip
                                    label="02"
                                    size="small"
                                    sx={{
                                      bgcolor: theme.palette.warning.dark,
                                      color: theme.palette.background.default,
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            }
                          />
                        </ListItemButton>
                        <ListItemButton
                          sx={{
                            borderRadius: `${customization.borderRadius}px`,
                          }}
                          selected={selectedIndex === 4}
                          onClick={() => onLogout()}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3em" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="body2">Logout</Typography>
                            }
                          />
                        </ListItemButton>
                      </List>
                    </Box>
                  </PerfectScrollbar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default Profil;
