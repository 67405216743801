import PropTypes from "prop-types";
import { useState } from "react";
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  ButtonBase,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import { IconAdjustmentsHorizontal, IconSearch, IconX } from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import PopupState, { bindToggle } from "material-ui-popup-state";
import { connect } from "react-redux";

const OutlineInputStyle = styled(OutlinedInput, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      paddingLeft: "4px !important",
      border: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderLeft: "1px solid #DADADA",
      borderRadius: 0,
      focusBorderColor: "none",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
    "&:hover": {
      background: theme.palette.secondary.dark,
      color: theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="Search"
      startAdornment={
        <InputAdornment position="start">
          <IconSearch
            stroke={1.5}
            size="1rem"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <ButtonBase sx={{ borderRadius: "12px" }}>
            <HeaderAvatarStyle variant="rounded">
              <IconAdjustmentsHorizontal stroke={1.5} size="1.3rem" />
            </HeaderAvatarStyle>
          </ButtonBase>
          <Box sx={{ ml: 2 }}>
            <ButtonBase sx={{ borderRadius: "12px" }}>
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.mediumAvatar,
                  background: theme.palette.orange.light,
                  color: theme.palette.orange.dark,
                  "&:hover": {
                    background: theme.palette.orange.dark,
                    color: theme.palette.orange.light,
                  },
                }}
                {...bindToggle(popupState)}
              >
                <IconX stroke={1.5} size="1.3rem" />
              </Avatar>
            </ButtonBase>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const Searchbar = ({
  FILTER_USER,
  RESET_FILTER,
  FILTER_EO,
  RESET_FILTER_EO,
  FILTER_MENU_EVENT,
  RESET_FILTER_MENU_EVENT,
  FILTER_CERTIFICATE,
  RESET_FILTER_CERTIFICATE,
}) => {
  const theme = useTheme();
  const [searchQuery, setSearchQuery] = useState("");

  const isMenuEventPage = window.location.pathname === "/menu-event";
  const isEventOrganizerPage = window.location.pathname === "/event-organizer";
  const isUserPage = window.location.pathname === "/user";
  const isCertificatePage = window.location.pathname === "/certificate";

  // const state = useSelector((state) => state);
  // console.log("State Redux Search Bar : ", state);

  const getFilter = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    // console.log("Query : ", query);
    if (isMenuEventPage) {
      if (query === "" || !query) {
        RESET_FILTER_MENU_EVENT();
      }
      FILTER_MENU_EVENT(query);
    } else if (isEventOrganizerPage) {
      if (query === "" || !query) {
        RESET_FILTER_EO();
      } else {
        FILTER_EO(query);
      }
    } else if (isUserPage) {
      if (query === "" || !query) {
        RESET_FILTER();
      } else {
        FILTER_USER(searchQuery);
      }
    } else if (isCertificatePage) {
      if (query === "" || !query) {
        RESET_FILTER_CERTIFICATE();
      } else {
        FILTER_CERTIFICATE(searchQuery);
      }
    }
  };

  return (
    <Box
      sx={{
        display: { xs: "block", md: "block" },
        width: "50%",
        borderRadius: 0,
        background: "transparent",
      }}
    >
      <OutlineInputStyle
        id="input-search-header"
        value={searchQuery}
        onChange={getFilter}
        placeholder="Search"
        startAdornment={
          <InputAdornment position="start">
            <IconSearch
              stroke={1.5}
              size="1.5rem"
              color={theme.palette.grey[500]}
            />
          </InputAdornment>
        }
        aria-describedby="search-helper-text"
        inputProps={{
          "aria-label": "weight",
          style: { backgroundColor: "transparent" },
        }}
        sx={{
          background: "transparent",
        }}
      />
    </Box>
  );
};

const matchStateToProps = (state) => {
  return {
    USER: state.userReducer.userFilter,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    FILTER_USER: (data) => dispatch({ type: "FILTER_USERS", value: data }),
    RESET_FILTER: () => dispatch({ type: "RESET_FILTER" }),
    FILTER_EO: (data) => dispatch({ type: "FILTER_EO", value: data }),
    RESET_FILTER_EO: () => dispatch({ type: "RESET_FILTER_EO" }),
    FILTER_MENU_EVENT: (data) =>
      dispatch({ type: "FILTER_MENU_EVENT", value: data }),
    RESET_FILTER_MENU_EVENT: () =>
      dispatch({ type: "RESET_FILTER_MENU_EVENT" }),
    FILTER_CERTIFICATE: (data) =>
      dispatch({ type: "FILTER_CERTIFICATE", value: data }),
    RESET_FILTER_CERTIFICATE: () =>
      dispatch({ type: "RESET_FILTER_CERTIFICATE" }),
  };
};

export default connect(matchStateToProps, mapDispatchToProps)(Searchbar);
