import { IconChevronDown } from "@tabler/icons";
import React from "react";

const Input = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <input
        type="text"
        style={{
          width: `179px`,
          height: "30px",
          border: "none",
          backgroundColor: "transparent",
          padding: 0,
          fontWeight: 400,
          fontSize: "14px",
          color: "#737373",
          textAlign: "start",
          fontStyle: "normal",
        }}
        value={props.value}
        onChange={(e) => {
          const newValue = e.target.value;
          const updateEvent = { ...props.eventDetail };
          if (props.label === "Jumlah Maksimum Peserta") {
            updateEvent.maximumTicket = parseInt(newValue);
            props.setEventDetail(updateEvent);
          }
          if (props.label === "Link Akses Event") {
            updateEvent.accessEventLink = newValue;
            props.setEventDetail(updateEvent);
          }
          if (props.label === "Link Group Whatsapp / Telegram / Lainnya") {
            updateEvent.contact = newValue;
            props.setEventDetail(updateEvent);
          }
          if (props.label === "Judul Event") {
            updateEvent.title = newValue;
            props.setEventDetail(updateEvent);
          }
        }}
      />
      <IconChevronDown size={15} />
    </div>
  );
};

export default Input;
