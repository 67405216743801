const initialState = {
  certificateState: {},
  certificateFilter: {},
};

const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CERTIFICATE":
      return {
        ...state,
        certificateState: action.value,
      };

    case "FILTER_CERTIFICATE":
      const filteredCertificate = state.certificateState.filter(
        (certificate) =>
          certificate.eventType
            .toLowerCase()
            .includes(action.value.toLowerCase()) ||
          certificate.amountOfParticipants
            .toString()
            .toLowerCase()
            .includes(action.value.toLowerCase()) ||
          certificate.total
            .toString()
            .toLowerCase()
            .includes(action.value.toLowerCase())
      );
      return {
        ...state,
        certificateFilter: filteredCertificate,
      };

    case "RESET_FILTER_CERTIFICATE":
      return {
        ...state,
        certificateState: state.certificateState,
      };

    case "RESET_STATE":
      return initialState;

    default:
      return state;
  }
};

export default certificateReducer;
