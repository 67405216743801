const initialState = {
  menuEventState: {},
  menuEventFilter: {},
};

const menuEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MENU_EVENT":
      return {
        ...state,
        menuEventState: action.value,
      };

    case "FILTER_MENU_EVENT":
      const filteredMenuEvent = state.menuEventState.filter(
        (menuEvent) =>
          menuEvent.title.toLowerCase().includes(action.value.toLowerCase()) ||
          menuEvent.eventOrganizer.name
            .toLowerCase()
            .includes(action.value.toLowerCase()) ||
          menuEvent.totalParticipants
            .toString()
            .toLowerCase()
            .includes(action.value.toLowerCase())
      );
      return {
        ...state,
        menuEventFilter: filteredMenuEvent,
      };

    case "RESET_FILTER_MENU_EVENT":
      return {
        ...state,
        menuEventState: state.menuEventState,
      };

    case "RESET_STATE":
      return initialState;

    default:
      return state;
  }
};

export default menuEventReducer;
