import ExistSession from "../../components/Modals/ExistsSession";
import { showToast } from "../../components/Partials/Toast";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner,
  Label,
} from "reactstrap";
import { alphanumeric } from "../../utils/helpers";
import "../../assets/css/custom/styles.css";
import { UseAuth } from "../../routes/useAuth";
import API from "../../utils/API";
import jwtDecode from "jwt-decode";

const Login = () => {
  const useAuth = UseAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const onLogin = () => {
    setLoading(true);
    const user = {
      username,
      password,
    };

    API.post("auths/login", user)
      .then((res) => {
        setLoading(false);
        const decode = jwtDecode(res.data.data.accessToken);
        const data = {
          accessToken: res.data.data.accessToken,
          role: decode.roles[1].name,
        };

        // console.log("Data Response : ", data);
        useAuth.login(data);
      })
      .catch((err) => {
        // console.log("Data Error : ", err.response.data);
        setLoading(false);
        if (err.response.data.code === 409) setIsOpen(true);
        setMessage(err.response.data.data);
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  return (
    <>
      <Col xl="9" lg="10" md="11">
        <Card className="zbg-secondary shadow border-0 my-0 my-lg-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1 className="card-login">
                <img
                  // onClick={() => navigation.push('/')}
                  role="button"
                  className="ml--2 card-login-img"
                  width={50}
                  src={require("../../assets/img/brand/logo_hievents.png")}
                />
                HiEvents
              </h1>
              <h1>Masuk</h1>
              <div className="card-login">
                Semangat Monitoring Dashboard nya yahh :D
              </div>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <Label>Username</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email kamu disini"
                    type="text"
                    onChange={(e) => setUsername(alphanumeric(e.target.value))}
                    value={username}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Password</Label>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password kamu disini"
                    type="password"
                    autoComplete="new-password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  disabled={loading}
                  onClick={onLogin}
                  className="my-4 w-100"
                  color="primary"
                  type="button"
                >
                  {loading ? <Spinner size="sm" /> : null} Masuk
                </Button>
              </div>
            </Form>
            <Row className="mt-3">
              <Col xs="6">
                <a className="text-light" href="/auth/forgot-pasword"></a>
              </Col>
              <Col className="text-right" xs="6">
                <Link to="/auth/register">
                  <small>Lupa Password ?</small>
                </Link>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>

      <ExistSession props={{ isOpen, setIsOpen, message, setMessage }} />
    </>
  );
};

export default Login;
