import { Divider, Paper, Stack, Grid, Chip } from "@mui/material";
import { FormattedDate } from "../../hooks/FormattedDate";
import React from "react";
import API from "../../utils/API";
import Input from "../Partials/Input";
import InputDate from "../Partials/InputDate";
import EventSelect from "../Partials/EventSelect";
import DialogSelect from "../Partials/DialogSelect";
import moment from "moment/moment";

const DetailCard = (props) => {
  // console.log("Data Event Detail : ", props.data);

  const [eventType, setEventType] = React.useState([]);
  const [eventContext, setEventContext] = React.useState([]);
  const [benefit, setBenefit] = React.useState([]);
  const [precondition, setPrecondition] = React.useState([]);

  const [startDate, setStartDate] = React.useState({});

  const getEventType = () => {
    API.get("eventTypes/getEventTypes", {
      Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    })
      .then((res) => {
        // console.log("Data Event Type : ", res.data.data);
        setEventType(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getEventContext = () => {
    API.get("eventContexts/getEventContexts", {
      Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    })
      .then((res) => {
        // console.log("Data Event Context : ", res.data.data);
        setEventContext(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBenefit = () => {
    API.get("benefits/getBenefits", {
      Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    })
      .then((res) => {
        // console.log("Dat Benefit: ", res.data.data);
        setBenefit(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPrecondition = () => {
    API.get("preconditions/getPreconditions", {
      Headers: { Authorization: `Bearer ${props.useAuth.auth.accessToken}` },
    })
      .then((res) => {
        // console.log("Data Precondition : ", res.data.data);
        setPrecondition(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getEventType();
    getEventContext();
    getBenefit();
    getPrecondition();
  }, []);

  const item = [
    {
      id: "Jenis Event",
      value: props.data.eventType?.name,
      edit: (
        <EventSelect
          {...{
            label: "Jenis Event",
            data: eventType,
            value: props.data.eventType?._id,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Judul Event",
      value: props.data.title,
      edit: (
        <Input
          {...{
            label: "Judul Event",
            value: props.data.title,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Konteks Event",
      value: props.data.eventContext?.name,
      edit: (
        <EventSelect
          {...{
            label: "Konteks Event",
            data: eventContext,
            value: props.data.eventContext?._id,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Biaya",
      value: props.data.paidStatus === "free" ? "Gratis" : "Berbayar",
      edit: (
        <EventSelect
          {...{
            label: "Biaya",
            value: props.data.paidStatus,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Jumlah Maksimum Peserta",
      value:
        props.data.maximumTicket === null
          ? "Tak Terbatas"
          : props.data.maximumTicket,
      edit: (
        <Input
          {...{
            label: "Jumlah Maksimum Peserta",
            value:
              props.data.maximumTicket === null
                ? "Tak Terbatas"
                : props.data.maximumTicket,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Tentang Event",
      value: props.data.description,
    },
    {
      // Mapping For Array
      id: "Benefit Untuk Peserta",
      value: (
        <Stack direction={"row"} spacing={1}>
          {Array.isArray(props.data.benefitIds) &&
            props.data.benefitIds.map((item) => (
              <Chip
                label={item.name}
                key={item._id}
                sx={{
                  borderRadius: "5px",
                  color: "#FFFFFF",
                  backgroundColor: "#FECA13",
                  fontWeight: 400,
                  fontSize: "14px",
                  fontStyle: "normal",
                }}
              />
            ))}
        </Stack>
      ),
      edit: (
        <DialogSelect
          {...{
            label: "Benefit Untuk Peserta",
            data: benefit,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      // Mapping For Array
      id: "Prasyarat",
      value: (
        <Stack direction={"row"} spacing={1}>
          {Array.isArray(props.data.preconditionIds) &&
            props.data.preconditionIds.map((item) => (
              <Chip
                label={item.name}
                key={item._id}
                sx={{
                  borderRadius: "5px",
                  color: "#FFFFFF",
                  backgroundColor: "#FECA13",
                  fontWeight: 400,
                  fontSize: "14px",
                  fontStyle: "normal",
                }}
              />
            ))}
        </Stack>
      ),
      edit: (
        <DialogSelect
          {...{
            label: "Prasyarat",
            data: precondition,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Link Akses Event",
      value: props.data.accessEventLink,
      edit: (
        <Input
          {...{
            label: "Link Akses Event",
            value: props.data.accessEventLink,
            data: props.data.accessEventLink,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Link Group Whatsapp / Telegram / Lainnya",
      value: props.data.contact,
      edit: (
        <Input
          {...{
            label: "Link Group Whatsapp / Telegram / Lainnya",
            value: props.data.contact,
            data: props.data.contact,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Tanggal Mulai Event",
      value: FormattedDate(props.data.startDate),
      edit: (
        <InputDate
          {...{
            label: "Tanggal Mulai Event",
            value: props.data.startDate,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Tanggal Berakhir Event",
      value: FormattedDate(props.data.endDate),
      edit: (
        <InputDate
          {...{
            label: "Tanggal Berakhir Event",
            value: props.data.endDate,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Deadline Pendaftaran",
      value: FormattedDate(props.data.deadlineRegistration),
      edit: (
        <InputDate
          {...{
            label: "Deadline Pendaftaran",
            value: props.data.deadlineRegistration,
            eventDetail: props.data,
            setEventDetail: props.setData,
          }}
        />
      ),
    },
    {
      id: "Deadline Absensi",
      value: "Belum Ada Data",
    },
  ];

  const itemUser = [
    {
      id: "Id User",
      value: props.data._id,
    },
    {
      id: "Username",
      value: props.data.username,
    },
    {
      id: "Nama Lengkap",
      value: props.data.name,
    },
    {
      id: "Email",
      value: props.data.email,
    },
    {
      id: "Kota Domisili",
      value: props.data.cityId?.name,
    },
    {
      id: "Nomor Wa",
      value: "0" + props.data.phoneNumber,
    },
    {
      id: "Event Diikuti",
      value: props.data.followedEvent ?? 0,
    },
    {
      id: "EO Diikuti",
      value: props.data.followedEo ?? 0,
    },
    {
      id: "Nama Akun EO",
      value: props.data.accountNameEo ?? "User Ini Bukan Event Organizer",
    },
  ];

  const itemEO = [
    {
      id: "Id User",
      value: props.data._id,
    },
    {
      id: "Nama EO",
      value: props.data.name ?? "",
    },
    {
      id: "Username",
      value: props.data.userId?.username ?? "",
    },
    {
      id: "Nama Lengkap",
      value: props.data.userId?.name ?? "",
    },
    {
      id: "Email",
      value: props.data.email ?? "",
    },
    {
      id: "Jumlah Pengikut",
      value: props.data.totalFollowers ?? 0,
    },
    {
      id: "Total Event yang Dibuat",
      value: props.data.totalEvent ?? 0,
    },
    {
      id: "Create Account",
      value: moment(props.data.createdAt).format("DD MMMM yy") ?? 0,
    },
    {
      id: "Tentang Event",
      value: props.data.about ?? 0,
    },
    {
      id: "WhatsApp",
      value:
        props.data.whatsapp === "0"
          ? "Tidak Ada Whatsapp"
          : props.data.whatsapp ?? 0,
    },
    {
      id: "Instagram",
      value:
        props.data.instagram === "0"
          ? "Tidak Ada Instagram"
          : props.data.instagram ?? 0,
    },
    {
      id: "Youtube",
      value:
        props.data.youtube === "0"
          ? "Tidak Ada Youtube"
          : props.data.youtube ?? 0,
    },
    {
      id: "Tiktok",
      value:
        props.data.tiktok === "0" ? "Tidak Ada Tiktok" : props.data.tiktok ?? 0,
    },
  ];

  return (
    <Paper
      elevation={1}
      sx={{
        backgroundColor: "white",
        marginY: 2,
        padding: 2,
        borderRadius: "4px",
        display: "flex",
        alignItems: `${props.data !== undefined ? "start" : "center"}`,
        justifyContent: `${props.data !== undefined ? "start" : "center"}`,
      }}
    >
      {props.data !== undefined ? (
        <Stack direction={"row"} gap={2} sx={{ width: "100%" }}>
          <img
            src={
              props.label === "Event"
                ? props.data.photo?.url
                : props.label === "User"
                ? props.data.photo?.url
                : props.label === "Event Organizer"
                ? props.data.logo?.url
                : null
            }
            alt={
              props.label === "Event"
                ? "Event Image"
                : props.label === "User"
                ? "User Image"
                : null
            }
            style={{
              width: "160px",
              height: "167px",
            }}
          />
          <Stack
            direction={"column"}
            divider={<Divider orientation="horizontal" flexItem />}
            spacing={1}
            sx={{ width: "100%" }}
          >
            {props.label === "Event"
              ? item.map((item) =>
                  item.id === "Tentang Event" ? (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      key={item.id}
                      alignItems={"center"}
                    >
                      <Grid item xs={9}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          {item.id.toUpperCase()}
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#737373",
                            textAlign: "start",
                            fontStyle: "normal",
                          }}
                          dangerouslySetInnerHTML={{ __html: item.value }}
                        ></div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      key={item.id}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      <Grid item xs={4} alignItems={"center"} display={"flex"}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          {item.id.toUpperCase()}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        alignItems={"center"}
                        display={"flex"}
                        flexWrap={"wrap"}
                      >
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#737373",
                            textAlign: "start",
                            fontStyle: "normal",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {(item.id === "Benefit Untuk Peserta" ||
                            item.id === "Prasyarat") &&
                          props.edit
                            ? item.value
                            : null}
                          {props.edit ? item.edit : item.value}
                        </div>
                      </Grid>
                    </Grid>
                  )
                )
              : props.label === "User"
              ? itemUser.map((item) => (
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    key={item.id}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <Grid item xs={2} alignItems={"center"} display={"flex"}>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#525252",
                          textAlign: "start",
                        }}
                      >
                        {item.id.toUpperCase()}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      display={"flex"}
                      flexWrap={"wrap"}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#737373",
                          textAlign: "start",
                          fontStyle: "normal",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {item.value}
                      </div>
                    </Grid>
                  </Grid>
                ))
              : props.label === "Event Organizer"
              ? itemEO.map((item) => (
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    key={item.id}
                    alignItems={"center"}
                    display={"flex"}
                  >
                    <Grid item xs={2} alignItems={"center"} display={"flex"}>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          color: "#525252",
                          textAlign: "start",
                        }}
                      >
                        {item.id.toUpperCase()}
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      display={"flex"}
                      flexWrap={"wrap"}
                    >
                      <div
                        style={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#737373",
                          textAlign: "start",
                          fontStyle: "normal",
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                          gap: "5px",
                        }}
                      >
                        {item.value}
                      </div>
                    </Grid>
                  </Grid>
                ))
              : props.label === "Event Organizer"
              ? item.map((item) =>
                  item.id === "Tentang Event" ? (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      key={item.id}
                      alignItems={"center"}
                    >
                      <Grid item xs={9} sx={{ paddingRight: 10 }}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          {item.id.toUpperCase()}
                        </div>
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#737373",
                            textAlign: "start",
                            fontStyle: "normal",
                          }}
                          dangerouslySetInnerHTML={{ __html: item.value }}
                        ></div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      rowSpacing={0}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                      key={item.id}
                      alignItems={"center"}
                      display={"flex"}
                    >
                      <Grid item xs={4} alignItems={"center"} display={"flex"}>
                        <div
                          style={{
                            fontWeight: "bold",
                            fontSize: "14px",
                            color: "#525252",
                            textAlign: "start",
                          }}
                        >
                          {item.id.toUpperCase()}
                        </div>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        alignItems={"center"}
                        display={"flex"}
                        flexWrap={"wrap"}
                      >
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "14px",
                            color: "#737373",
                            textAlign: "start",
                            fontStyle: "normal",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "5px",
                          }}
                        >
                          {(item.id === "Benefit Untuk Peserta" ||
                            item.id === "Prasyarat") &&
                          props.edit
                            ? item.value
                            : null}
                          {props.edit ? item.edit : item.value}
                        </div>
                      </Grid>
                    </Grid>
                  )
                )
              : null}
          </Stack>
        </Stack>
      ) : (
        <p>No Data Available</p>
      )}
    </Paper>
  );
};

export default DetailCard;
