import { useRoutes } from "react-router-dom";

import UserLayout from "../layouts/User";
import AuthLayout from "../layouts/Auth";
import { lazy } from "react";

import Loadable from "../components/ui-component/Loadable";
import Deposit from "../views/deposit";
import Withdraw from "../views/withdraw";
import CustomEvent from "../views/customEvent";
import CustomCertificate from "../views/customCertificate";

import DetailMenuEvent from "../views/menuEvent/Detail";
import DetailEventOrganizer from "../views/dataEventOrganizer/Detail";
import DetailUser from "../views/dataUser/Detail";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("../views/dashboard")));
const MenuEvent = Loadable(lazy(() => import("../views/menuEvent")));
// const DetailMenuEvent = Loadable(
//   lazy(() => import("../views/menuEvent/Detail"))
// );
const EventOrganizer = Loadable(
  lazy(() => import("../views/dataEventOrganizer"))
);
const User = Loadable(lazy(() => import("../views/dataUser")));
const Benefit = Loadable(lazy(() => import("../views/profit")));
const Certificate = Loadable(lazy(() => import("../views/certificate")));
const Saran = Loadable(lazy(() => import("../views/saranKritik")));
const Report = Loadable(lazy(() => import("../views/report")));
const RedCheckSubmission = Loadable(
  lazy(() => import("../views/redCheckSubmission"))
);

// import Dashboard from "../views/dashboard";
// import MenuEvent from "../views/menuEvent";
// import EventOrganizer from "../views/dataEventOrganizer";
// import User from "../views/dataUser";
// import Benefit from "../views/profit";
// import Certificate from "../views/certificate";

// import Saran from "../views/saranKritik";
// import Report from "../views/report";
// import RedCheckSubmission from "../views/redCheckSubmission";

// import Deposit from "../views/deposit";
// import Withdraw from "../views/withdraw";

// import CustomEvent from "../views/customEvent";
// import CustomCertificate from "../views/customCertificate";
// import Login from "../views/auth/Login";

// ==============================|| ROUTING RENDER ||============================== //
const userRouter = {
  path: "/",
  element: <UserLayout />,
  children: [
    {
      path: "dashboard",
      element: <Dashboard />,
    },
    {
      path: "menu-event",
      element: <MenuEvent />,
    },
    {
      path: "menu-event/detail/:slug",
      element: <DetailMenuEvent />,
    },
    {
      path: "event-organizer",
      element: <EventOrganizer />,
    },
    {
      path: "event-organizer/detail/:id",
      element: <DetailEventOrganizer />,
    },
    {
      path: "user",
      element: <User />,
    },
    {
      path: "user/detail/:id",
      element: <DetailUser />,
    },
    {
      path: "benefit",
      element: <Benefit />,
    },
    {
      path: "certificate",
      element: <Certificate />,
    },
    {
      path: "saran-kritik",
      element: <Saran />,
    },
    {
      path: "report",
      element: <Report />,
    },
    {
      path: "red-check-submission",
      element: <RedCheckSubmission />,
    },
    {
      path: "deposit",
      element: <Deposit />,
    },
    {
      path: "withdraw",
      element: <Withdraw />,
    },
    {
      path: "custom-event",
      element: <CustomEvent />,
    },
    {
      path: "custom-certificate",
      element: <CustomCertificate />,
    },
  ],
};

const authRouter = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "auth/login",
      element: <AuthLayout />,
    },
  ],
};

const ThemeRoutes = (props) => {
  const isLogin = props.isLogin === true;
  const hasAccessToken =
    props.accessToken !== "" &&
    props.accessToken !== "null" &&
    props.accessToken !== undefined;

  if (isLogin && hasAccessToken && (!isLogin || hasAccessToken)) {
    // console.log("masuk User Router");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRoutes([userRouter]);
  } else {
    // console.log("masuk Auth Router");
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useRoutes([authRouter]);
  }
};

export default ThemeRoutes;
