const HeaderTable = [
  {
    name: "menuEvent",
    items: [
      { id: "title", label: "JUDUL EVENT", minWidth: 170, align: "left" },
      {
        id: "eventOrganizer",
        label: "EVENT ORGANIZER",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "totalParticipants",
        label: "JUMLAH PESERTA",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "action",
        label: "AKSI",
        minWidth: 100,
        align: "center",
      },
    ],
  },
  {
    name: "detail_peserta_event",
    items: [
      { id: "idUser", label: "ID USER", minWidth: 100, align: "left" },
      {
        id: "username",
        label: "USERNAME",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "namaLengkap",
        label: "NAMA LENGKAP",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "email",
        label: "EMAIL",
        minWidth: 100,
        align: "center",
      },
      {
        id: "cityAddress",
        label: "KOTA DOMISILI",
        minWidth: 100,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "waNumber",
        label: "NOMOR WA",
        minWidth: 100,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "registrationLimit",
        label: "WAKTU PENDAFTARAN",
        minWidth: 100,
        align: "center",
        format: (value) => value.toFixed(2),
      },
    ],
  },
  {
    name: "eventOrganizer",
    items: [
      { id: "_id", label: "ID USER", minWidth: 170 },
      {
        id: "logo",
        label: "FOTO",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "name",
        label: "NAMA EO",
        minWidth: 350,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "username",
        label: "USERNAME",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "namaEo",
        label: "NAMA LENGKAP",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "emailEo",
        label: "EMAIL",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "totalFollowers",
        label: "JUMLAH PENGIKUT",
        minWidth: 170,
        align: "center",
        format: (value) => value,
      },
      {
        id: "totalEvents",
        label: "TOTAL EVENT DIBUAT",
        minWidth: 170,
        align: "center",
        format: (value) => value,
      },
      {
        id: "createdAt",
        label: "CREATED ACCOUNT",
        minWidth: 200,
        align: "center",
        format: (value) => value,
      },
      {
        id: "action",
        label: "AKSI",
        minWidth: 100,
        align: "center",
      },
    ],
  },
  {
    name: "user",
    items: [
      { id: "_id", label: "ID USER", minWidth: 170 },
      {
        id: "username",
        label: "USERNAME",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "name",
        label: "NAMA LENGKAP",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "email",
        label: "EMAIL",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "cityId.name",
        label: "KOTA DOMISILI",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "phone",
        label: "NOMOR WA",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "createdAt",
        label: "CREATED ACCOUNT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "action",
        label: "AKSI",
        minWidth: 100,
        align: "center",
      },
    ],
  },
  {
    name: "benefit",
    items: [
      { id: "eventType", label: "JENIS EVENT", minWidth: 170 },
      { id: "totalEvent", label: "JUMLAH EVENT", minWidth: 100 },
      {
        id: "totalAudience",
        label: "JUMLAH PESERTA",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "totalTicketSales",
        label: "TOTAL PENJUALAN TIKET",
        minWidth: 170,
        align: "right",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "ourGratuity",
        label: "PERSENAN KITA",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
      {
        id: "fixedFees",
        label: "FIXED FEES",
        minWidth: 170,
        align: "right",
        format: (value) => value.toFixed(2),
      },
    ],
  },
  {
    name: "certificate",
    items: [
      {
        id: "eventType",
        label: "JENIS EVENT BERSERTIFIKAT",
        minWidth: 170,
        align: "left",
      },
      { id: "total", label: "JUMLAH EVENT", minWidth: 100, align: "center" },
      {
        id: "amountOfParticipants",
        label: "JUMLAH PESERTA",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      // {
      //   id: "totalTicketSales",
      //   label: "BATAS PENDAFTARAN",
      //   minWidth: 170,
      //   align: "center",
      //   format: (value) => value.toLocaleString("en-US"),
      // },
    ],
  },
  {
    name: "certificateByType",
    items: [
      { id: "eventContext", label: "KATEGORI EVENT", minWidth: 170 },
      { id: "total", label: "JUMLAH EVENT", minWidth: 100, align: "center" },
      {
        id: "amountOfParticipants",
        label: "JUMLAH PESERTA",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      // {
      //   id: "totalTicketSales",
      //   label: "BATAS PENDAFTARAN",
      //   minWidth: 170,
      //   align: "center",
      //   format: (value) => value.toLocaleString("en-US"),
      // },
      // {
      //   id: "ourGratuity",
      //   label: "DETAIL",
      //   minWidth: 170,
      //   align: "center",
      //   format: (value) => value.toFixed(2),
      // },
    ],
  },
  {
    name: "saran-kritik",
    items: [
      { id: "no", label: "NO", minWidth: 170, align: "left" },
      { id: "email", label: "EMAIL", minWidth: 100, align: "center" },
      {
        id: "saranKritik",
        label: "SARAN / KRITIK",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "aksi",
        label: "AKSI",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "report",
    items: [
      { id: "no", label: "NO", minWidth: 50, align: "left" },
      { id: "username", label: "USERNAME", minWidth: 100, align: "center" },
      {
        id: "email",
        label: "EMAIL",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "eoYangReport",
        label: "EO YANG DI REPORT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "alasan",
        label: "ALASAN REPORT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "idEvent",
        label: "ID EVENT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "namaEvent",
        label: "NAMA EVENT",
        minWidth: 400,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "aksi",
        label: "AKSI EVENT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "red-check-submission",
    items: [
      { id: "namaEo", label: "NAMA EO", minWidth: 50, align: "left" },
      { id: "idEo", label: "ID EO", minWidth: 100, align: "center" },
      {
        id: "tglPengajuan",
        label: "TGL PENGAJUAN",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "tinjau",
        label: "TINJAU",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "aksi",
        label: "AKSI",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "deposit",
    items: [
      { id: "namaEo", label: "NAMA EO", minWidth: 50 },
      {
        id: "jenisEvent",
        label: "JENIS EVENT",
        minWidth: 50,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "jumlahPeserta",
        label: "JUMLAH PESERTA",
        minWidth: 50,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "saldo",
        label: "SALDO PER EVENT",
        minWidth: 50,
        align: "center",
        format: (value) => value.toFixed(2),
      },
      {
        id: "batasPendaftaran",
        label: "BATAS PENDAFTARAN",
        minWidth: 50,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "withdraw",
    items: [
      { id: "namaEo", label: "NAMA EO", minWidth: 50, align: "left" },
      {
        id: "jumlahPengikut",
        label: "JUMLAH PENGIKUT",
        minWidth: 100,
        align: "center",
      },
      {
        id: "saldoUtama",
        label: "SALDO UTAMA",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "saldoDirequest",
        label: "SALDO DIREREQUEST",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "tinjau",
        label: "TINJAU",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "aksi",
        label: "AKSI EVENT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "custom-event",
    items: [
      { id: "eventTitle", label: "JUDUL EVENT", maxWidth: 170 },
      {
        id: "action",
        label: "EDIT EVENT",
        minWidth: 100,
        align: "left",
      },
    ],
  },
  {
    name: "custom-certificate",
    items: [
      { id: "namaEo", label: "NAMA EO", minWidth: 50, align: "left" },
      { id: "noWa", label: "NOMOR WA", minWidth: 100, align: "center" },
      {
        id: "tinjau",
        label: "TINJAU",
        minWidth: 100,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "aksi",
        label: "AKSI EVENT",
        minWidth: 170,
        align: "center",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
  {
    name: "detail-event-organizer",
    items: [
      {
        id: "jeniEvent",
        label: "JENIS EVENT",
        minWidth: 50,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "judulEvent",
        label: "JUDUL EVENT",
        minWidth: 100,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "eventContext",
        label: "KONTEKS EVENT",
        minWidth: 100,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "batasPendaftaran",
        label: "BATAS PENDAFTARAN",
        minWidth: 170,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
      {
        id: "mulaiEvent",
        label: "MULAI EVENT",
        minWidth: 170,
        align: "left",
        format: (value) => value.toLocaleString("en-US"),
      },
    ],
  },
];

export default HeaderTable;
