import * as React from "react";
// import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Card, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Spinner } from "reactstrap";
// project imports
// import { gridSpacing } from '../../store/constant';

// ===============================|| Total Event And Others ||=============================== //
export const TotalEvent = ({ userCount, showText }) => {
  return (
    // Perubahan Pada parameter margin top dan bottom untuk mengatur style dengan responsive pada layar large dan desktop
    <Card
      Card
      sx={{
        boxShadow: "none",
        border: "none",
        backgroundColor: "primary.light",
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          py: { lg: 3.5, xs: 1 },
          bgcolor: "primary.light",
          color: "grey.800",
        }}
      >
        <Box
          sx={{
            color: "purple",
            fontWeight: "medium",
            fontSize: { lg: 20, xs: 16 },
          }}
        >
          Total {showText} {userCount}
        </Box>
      </Box>
    </Card>
  );
};

export const SaranTitle = () => {
  return (
    // Perubahan Pada parameter margin top dan bottom untuk mengatur style dengan responsive pada layar large dan desktop
    <Card
      Card
      sx={{
        my: { lg: -0.7, xs: 0 },
        boxShadow: "none",
        border: "none",
        borderRadius: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "center",
          py: { lg: 3.5, xs: 0 },
          bgcolor: "primary.light",
          color: "grey.800",
        }}
      >
        <Box
          sx={{
            color: "purple",
            fontWeight: "medium",
            fontSize: { lg: 20, xs: 14 },
          }}
        >
          Total Saran / Kritik User : 25,222
        </Box>
      </Box>
    </Card>
  );
};

export const EventReport = () => {
  return (
    // Perubahan Pada parameter margin top dan bottom untuk mengatur style dengan responsive pada layar large dan desktop
    <Card
      Card
      sx={{
        my: { lg: 2, xs: 0 },
        boxShadow: "none",
        border: "none",
        borderRadius: 0,
        backgroundColor: "primary.light",
      }}
    >
      <Box
        sx={{
          fontWeight: "medium",
          fontSize: { lg: 20, xs: 14 },
          display: "flex",
          alignItems: "center",
          py: { lg: 3.5, xs: 1 },
        }}
      >
        Total Event : 25,222
      </Box>
    </Card>
  );
};

export const TotalEventCertificate = (props) => (
  // Perubahan Pada parameter margin top dan bottom untuk mengatur style dengan responsive pada layar large dan desktop
  <Card
    sx={{
      boxShadow: "none",
      border: "none",
      backgroundColor: "primary.light",
      borderRadius: 0,
    }}
  >
    <Box
      sx={{
        fontWeight: "medium",
        fontSize: { lg: 20, xs: 14 },
        display: "flex",
        alignItems: "center",
        py: { lg: 3.5, xs: 1 },
      }}
    >
      {props.data}
    </Box>
  </Card>
);

// TotalEvent.propTypes = {
//     shadow: PropTypes.string.isRequired
// };

// Props Shadow dihapus karenan tidak digunakan
export const FilterCity = () => {
  return (
    <Card sx={{ mb: 3, boxShadow: "none", border: "none" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: { lg: 3.5, xs: 0 },
          bgcolor: "primary.light",
          color: "grey.800",
        }}
      >
        <FormControl fullWidth>
          <Select
            // value={age}
            // onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            defaultValue="None"
          >
            <MenuItem value="None">
              {" "}
              <em>None</em>
            </MenuItem>
            <MenuItem value="None">
              {" "}
              <em>None</em>
            </MenuItem>
            <MenuItem value="None">
              {" "}
              <em>None</em>
            </MenuItem>
            {/* {dataCity.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.cityName}>{item.cityName}</MenuItem>
                            )
                        })} */}
          </Select>
        </FormControl>
      </Box>
    </Card>
  );
};

// FilterCity.propTypes = {
//     shadow: PropTypes.string.isRequired
// };
export const SortBy = ({ shadow, onChangeSorting, onChangeSortingEo }) => {
  const [sortingOrder, setSortingOrder] = React.useState("None");
  const [sortingOrderEo, setSortingOrderEo] = React.useState("None");
  const isDataEo = window.location.pathname === "/event-organizer";

  return (
    <Card sx={{ mb: 0, boxShadow: shadow, width: isDataEo ? "100%" : "50%" }}>
      {/* 
        Perubahan Component Box Dihabus karena tidak digunukan lebih 
        efektif langsung dimasukan form select saja agar nyatu kedua komponen 
        */}
      <FormControl
        fullWidth
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          justifyContent: "center",
          alignItems: "center",
          py: { lg: 3.5, xs: 0 },
          bgcolor: "primary.light",
          color: "grey.800",
        }}
      >
        <Box sx={{ fontWeight: "medium", fontSize: { lg: 20, xs: 14 } }}>
          SortBy:{" "}
        </Box>
        {isDataEo ? (
          <Select
            // value={age}
            // onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            defaultValue="None"
            value={sortingOrderEo}
            onChange={(event) => {
              setSortingOrderEo(event.target.value);
              onChangeSortingEo(event.target.value);
            }}
            fullWidth
          >
            <MenuItem value="None">
              {" "}
              <em>None</em>{" "}
            </MenuItem>
            <MenuItem value="Terbaru">Terbaru</MenuItem>
            <MenuItem value="Terlama">Terlama</MenuItem>
          </Select>
        ) : (
          <Select
            // value={age}
            // onChange={handleChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            defaultValue="None"
            value={sortingOrder}
            onChange={(event) => {
              setSortingOrder(event.target.value);
              onChangeSorting(event.target.value);
            }}
            fullWidth
          >
            <MenuItem value="None">
              {" "}
              <em>None</em>{" "}
            </MenuItem>
            <MenuItem value="Terbaru">Terbaru</MenuItem>
            <MenuItem value="Terlama">Terlama</MenuItem>
          </Select>
        )}
      </FormControl>
    </Card>
  );
};

// Props shadows dihapus karena tidak digunakan
export const ExportButton = (props) => (
  <div
    className="text-center"
    style={{
      width: "20%",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <Button
      disabled={props.data}
      onClick={props.getExportButton}
      className="my-4 w-100"
      type="button"
      style={{
        backgroundColor: "transparent",
        border: "1px solid #DADADA",
        color: "#525252",
        height: "50%",
      }}
    >
      {props.data ? <Spinner size="sm" /> : "Export"}
    </Button>
  </div>
);

// ============================|| Content ||============================ //

const User = ({ userCount, showText }) => (
  // Perbaikan Pada GRID agar dapat berjalan secara responsive dengan layar large
  <Grid container spacing={2} columns={{ lg: 4, xs: 2 }}>
    <Grid item xs={8}>
      <Grid container spacing={1} columnSpacing={2}>
        <Grid item xs={12} lg={4}>
          <TotalEvent userCount={userCount} showText={showText} />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <FilterCity />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={3}>
          <SortBy shadow="0" />
        </Grid>
        <Grid item xs={4} sm={6} md={4} lg={2}>
          <ExportButton />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default User;
