import React from "react";
import Appbar from "../../components/items/Appbar";
import { useParams } from "react-router-dom";
import { UseAuth } from "../../routes/useAuth";
import API from "../../utils/API";
import DetailCard from "../../components/items/DetailCard";

const Detail = () => {
  const [editUser, setEditUser] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState({});

  const params = useParams();
  const useAuth = UseAuth();

  const getUserDetail = () => {
    API.get(`users/getUser/${params.id}`, {
      headers: { Authorization: `Bearer ${useAuth.auth.accessToken}` },
    })
      .then((res) => {
        console.log("Data User Detail : ", res.data.data);
        setUserDetail(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getUserDetail();
  }, []);

  return (
    <>
      <Appbar
        {...{
          title: "Detail User",
          routeBack: "/user",
          edit: editUser,
          setEdit: setEditUser,
        }}
      />
      <DetailCard
        {...{
          label: "User",
          data: userDetail,
          setData: setUserDetail,
          edit: editUser,
          useAuth,
        }}
      />
    </>
  );
};

export default Detail;
