const initialState = {
  userState: {},
  userSearch: {},
  userFilter: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        userState: action.value,
      };

    case "RESET_STATE":
      return initialState;

    case "GET_DATAUSER":
      return {
        ...state,
        userSearch: action.value,
      };

    case "FILTER_USERS":
      // Filter users based on a certain criteria (e.g., username)
      const filteredUsers = state.userState.filter(
        (user) =>
          user.name.toLowerCase().includes(action.value.toLowerCase()) ||
          user.email.toLowerCase().includes(action.value.toLowerCase()) ||
          user._id.toLowerCase().includes(action.value.toLowerCase()) ||
          user.username.toLowerCase().includes(action.value.toLowerCase()) ||
          user.cityId.name.toLowerCase().includes(action.value.toLowerCase()) ||
          user.createdAt.toLowerCase().includes(action.value.toLowerCase())
      );

      return {
        ...state,
        userFilter: filteredUsers,
      };

    case "RESET_FILTER":
      return {
        ...state,
        userSearch: state.userState,
      };

    default:
      return state;
  }
};

export default userReducer;
