import * as React from "react";
import { useState } from "react";

// mui imports
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

// project imports
import { InputGroup } from "reactstrap";

function ButtonActions() {
  return (
    <Stack spacing={2} direction="row">
      <Button variant="contained" color="warning">
        Edit
      </Button>
      <Button variant="contained" color="error">
        Hapus
      </Button>
    </Stack>
  );
}

const CustomEvent = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isActive, setIsActive] = React.useState([
    true,
    false,
    false,
    false,
    false,
  ]);
  const [rows, setRows] = useState([[], [], [], [], []]);
  const [inputValue, setInputValue] = useState("");

  // Function Untuk Create Data
  function createData(eventTitle) {
    return { eventTitle };
  }

  // Mapping Columns
  const columns = [
    { id: "eventTitle", label: "JUDUL EVENT", maxWidth: 170 },
    {
      id: "action",
      label: "EDIT EVENT",
      minWidth: 100,
      align: "left",
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const toggleButton = (index) => {
    const newIsActive = isActive.map((value, i) => i === index); // Mengubah semua elemen menjadi false kecuali tombol yang diklik
    setIsActive(newIsActive);
  };

  const addData = (index) => {
    if (inputValue.trim() !== "") {
      // Pastikan input tidak kosong
      const newData = createData(inputValue);
      const updatedRows = [...rows];
      updatedRows[index].push(newData);
      setRows(updatedRows);
      setInputValue(""); // Reset input setelah data ditambahkan
    }
  };

  // const handleAddData = (index) => {
  //     if (inputValue.trim() !== "") { // Pastikan nilai input tidak kosong
  //         console.log(inputValue);
  //         handleData(index, inputValue);
  //         setInputValue(""); // Reset input setelah data ditambahkan
  //     }
  // }

  return (
    <Grid>
      {/* Header */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "20px",
          marginBottom: "20px",
        }}
      >
        <Button
          sx={{
            fontSize: { lg: 20, xs: 16 },
            color: isActive[0] ? "#9870C5" : "#737373",
            fontWeight: 500,
            fontStyle: "normal",
            ":hover": {
              color: "#9870C5",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => toggleButton(0)}
        >
          Kategori Event
        </Button>
        <div
          style={{
            border: "1px solid #E0E0E0",
            height: "30px",
          }}
        ></div>
        <Button
          sx={{
            fontSize: { lg: 20, xs: 16 },
            color: isActive[1] ? "#9870C5" : "#737373",
            fontWeight: 500,
            fontStyle: "normal",
            ":hover": {
              color: "#9870C5",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => toggleButton(1)}
        >
          Sub Kategori
        </Button>
        <div
          style={{
            border: "1px solid #E0E0E0",
            height: "30px",
          }}
        ></div>
        <Button
          sx={{
            fontSize: { lg: 20, xs: 16 },
            color: isActive[2] ? "#9870C5" : "#737373",
            fontWeight: 500,
            fontStyle: "normal",
            ":hover": {
              color: "#9870C5",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => toggleButton(2)}
        >
          Jenjang
        </Button>
        <div
          style={{
            border: "1px solid #E0E0E0",
            height: "30px",
          }}
        ></div>
        <Button
          sx={{
            fontSize: { lg: 20, xs: 16 },
            color: isActive[3] ? "#9870C5" : "#737373",
            fontWeight: 500,
            fontStyle: "normal",
            ":hover": {
              color: "#9870C5",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => toggleButton(3)}
        >
          Benefit
        </Button>
        <div
          style={{
            border: "1px solid #E0E0E0",
            height: "30px",
          }}
        ></div>
        <Button
          sx={{
            fontSize: { lg: 20, xs: 16 },
            color: isActive[4] ? "#9870C5" : "#737373",
            fontWeight: 500,
            fontStyle: "normal",
            ":hover": {
              color: "#9870C5",
              backgroundColor: "transparent",
            },
          }}
          onClick={() => toggleButton(4)}
        >
          Prasyarat
        </Button>
      </div>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: 0,
          boxShadow: "1",
        }}
      >
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {isActive[0] ? (
                  <TableCell
                    colSpan={2}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: { lg: 14, xs: 10 },
                        padding: 2,
                      }}
                    >
                      Kategori
                    </Box>
                    <InputGroup
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Kategori Event"
                        style={{
                          width: "300px",
                          height: "30px",
                          borderRadius: "5px",
                          border: "1px solid #C4C4C4",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#5BE462",
                          color: "white",
                          padding: "3px 20px 3px 20px",
                          ":hover": {
                            color: "#525252",
                            backgroundColor: "transparent",
                            border: "1px solid #737373",
                          },
                        }}
                        onClick={() => addData(0)}
                      >
                        Tambah
                      </Button>
                    </InputGroup>
                  </TableCell>
                ) : isActive[1] ? (
                  <TableCell
                    colSpan={2}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: { lg: 14, xs: 10 },
                        padding: 2,
                        width: "100%",
                      }}
                    >
                      Sub Kategori
                    </Box>
                    <InputGroup
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <Select
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        defaultValue="Tegal"
                        sx={{
                          width: "200px",
                          height: "30px",
                          backgroundColor: "transparent",
                        }}
                      >
                        <MenuItem value="None">
                          {" "}
                          <em>None</em>
                        </MenuItem>
                      </Select>
                      <input
                        type="text"
                        placeholder="Sub Kategori"
                        style={{
                          width: "300px",
                          height: "30px",
                          borderRadius: "5px",
                          border: "1px solid #C4C4C4",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#5BE462",
                          color: "white",
                          padding: "3px 20px 3px 20px",
                          ":hover": {
                            color: "#525252",
                            backgroundColor: "transparent",
                            border: "1px solid #737373",
                          },
                        }}
                        onClick={() => addData(1)}
                      >
                        Tambah
                      </Button>
                    </InputGroup>
                  </TableCell>
                ) : isActive[2] ? (
                  <TableCell
                    colSpan={2}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: { lg: 14, xs: 10 },
                        padding: 2,
                      }}
                    >
                      Jenjang
                    </Box>
                    <InputGroup
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Jenjang"
                        style={{
                          width: "300px",
                          height: "30px",
                          borderRadius: "5px",
                          border: "1px solid #C4C4C4",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#5BE462",
                          color: "white",
                          padding: "3px 20px 3px 20px",
                          ":hover": {
                            color: "#525252",
                            backgroundColor: "transparent",
                            border: "1px solid #737373",
                          },
                        }}
                        onClick={() => addData(2)}
                      >
                        Tambah
                      </Button>
                    </InputGroup>
                  </TableCell>
                ) : isActive[3] ? (
                  <TableCell
                    colSpan={2}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: { lg: 14, xs: 10 },
                        padding: 2,
                      }}
                    >
                      Benefit
                    </Box>
                    <InputGroup
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="benefit"
                        style={{
                          width: "300px",
                          height: "30px",
                          borderRadius: "5px",
                          border: "1px solid #C4C4C4",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#5BE462",
                          color: "white",
                          padding: "3px 20px 3px 20px",
                          ":hover": {
                            color: "#525252",
                            backgroundColor: "transparent",
                            border: "1px solid #737373",
                          },
                        }}
                        onClick={() => addData(3)}
                      >
                        Tambah
                      </Button>
                    </InputGroup>
                  </TableCell>
                ) : isActive[4] ? (
                  <TableCell
                    colSpan={2}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        fontStyle: "normal",
                        fontSize: { lg: 14, xs: 10 },
                        padding: 2,
                      }}
                    >
                      Prasyarat
                    </Box>
                    <InputGroup
                      style={{
                        paddingLeft: 16,
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="Prasyarat"
                        style={{
                          width: "300px",
                          height: "30px",
                          borderRadius: "5px",
                          border: "1px solid #C4C4C4",
                        }}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        sx={{
                          backgroundColor: "#5BE462",
                          color: "white",
                          padding: "3px 20px 3px 20px",
                          ":hover": {
                            color: "#525252",
                            backgroundColor: "transparent",
                            border: "1px solid #737373",
                          },
                        }}
                        onClick={() => addData(4)}
                      >
                        Tambah
                      </Button>
                    </InputGroup>
                  </TableCell>
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {isActive[0]
                ? rows[0].map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eventTitle"
                                ? row.eventTitle
                                : null}
                              {column.id === "action" ? ButtonActions() : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : isActive[1]
                ? rows[1].map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eventTitle"
                                ? row.eventTitle
                                : null}
                              {column.id === "action" ? ButtonActions() : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : isActive[2]
                ? rows[2].map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eventTitle"
                                ? row.eventTitle
                                : null}
                              {column.id === "action" ? ButtonActions() : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : isActive[3]
                ? rows[3].map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eventTitle"
                                ? row.eventTitle
                                : null}
                              {column.id === "action" ? ButtonActions() : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : isActive[4]
                ? rows[4].map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                        {columns.map((column) => {
                          // const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "eventTitle"
                                ? row.eventTitle
                                : null}
                              {column.id === "action" ? ButtonActions() : null}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Grid>
  );
};

export default CustomEvent;
