import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import { AuthProvider } from "./routes/useAuth";
import { createStore } from "redux";

import reducer from "./reducer/index";

import "./assets/scss/style.scss";

const config = {
  basename: "/",
  defaultPath: "/dashboard",
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
};

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const store = createStore(reducer);

root.render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </BrowserRouter>
  </Provider>
);
