import { useParams } from "react-router-dom";
import Appbar from "../../components/items/Appbar";
import DetailCard from "../../components/items/DetailCard";
import React from "react";
import API from "../../utils/API";
import { UseAuth } from "../../routes/useAuth";
import DetailTable from "../../components/items/DetailTable";

const Detail = () => {
  const params = useParams();
  const [eventDetail, setEventDetail] = React.useState({});
  const [eventId, setEventId] = React.useState("");

  const [editEvent, setEditEvent] = React.useState(false);

  const useAuth = UseAuth();

  console.log("Params : ", params);
  const getEventDetail = () => {
    API.get(`events/getEvent/${params.slug}`, {
      header: { Authorization: `Bearer ${useAuth.auth.accessToken}` },
    })
      .then((res) => {
        // console.log("Data Event Detail : ", res.data.data);
        setEventDetail(res.data.data);
        setEventId(res.data.data._id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getEventDetail();
  }, []);

  console.log("Data Edit Event : ", editEvent);

  return (
    <>
      <Appbar
        {...{
          title: "Detail Event",
          routeBack: "/menu-event",
          edit: editEvent,
          setEdit: setEditEvent,
          data: eventDetail,
          setData: setEventDetail,
          params,
          useAuth,
          eventId,
        }}
      />
      <DetailCard
        {...{
          data: eventDetail,
          setData: setEventDetail,
          edit: editEvent,
          useAuth,
        }}
      />
      <DetailTable headerTitle={"Detail Peserta Event"} />
      <DetailTable headerTitle={"Detail Absensi"} />
    </>
  );
};

export default Detail;
